footer.appbar {
  background: var(--color-header);
  position: static;

  a {
    color: var(--color-text-gray) !important;
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

._short {
  background: var(--color-header);
  width: 100%;
  display: flex;
  justify-content: center;
  height: 64px;
  padding-top: 8px;
  padding-bottom: 8px;

  img {
    width: 150px;
  }
  @media (min-width: 1200px) {
    display: none;
  }
}

