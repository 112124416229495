.questions-part {
  background: var(--color-background);
  padding: 64px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;


  @media (max-width: 1200px) {
    padding: 64px 16px;
  }

  .card {
    width: 60%;
    margin-bottom: 24px;
    background-color: var(--color-white);
    //height: 100px;

    @media (max-width: 1200px) {
      width: calc(100% - 64px);
    }

    .title {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: margin-bottom 0.1s;
      transition-delay: 0s;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .text {
      max-height: 200px;
      overflow: hidden;
      display: block;
      transition: max-height .5s;
      @media (max-width: 1200px) {
        max-height: 500px;
      }
    }
    &._hidden {
      .title {
        margin-bottom: 0;
        transition-delay: .5s;
      }
      .text {
        max-height: 0;
      }
    }
  }
}
