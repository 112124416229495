.text-input {
  border: none;
  border-radius: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-white);

  .without-tooltip {
    .MuiInputBase-input {
      padding: 15px 12px;
    }
  }

  * {
    border: none !important;
  }

  .MuiInputBase-multiline {
    padding: 0;
  }

  .MuiInputBase-input {
    padding: 25px 12px 5px 12px;
    color: var(--color-text);
    font-weight: 400;
    font-size: 19px;
    line-height: 16.44px;

    &.MuiInputBase-inputMultiline {
      padding-top: 0;
      margin-top: 25px;
    }
  }

  .label, .label.Mui-focused {
    color: var(--color-placeholder);
    font-weight: 400;
    font-size: 14px;
    line-height: 21.13px;
    transform: translate(12px, 16.22px);

    &.MuiInputLabel-shrink {
      transform: translate(12px, 5px);
    }

    &.MuiInputLabel-animated {
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    }
  }
}
