body {
  min-width: 360px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    font-family: "Open Sans",sans-serif !important;
    color: var(--color-text);
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 19px;
  }

  #root {
    overflow-x: hidden;
  }
}

div.MuiPaper-root {
  padding: 24px 32px;
  border-radius: 0;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  background-color: var(--color-background-light);
  @media (max-width: 1200px) {
    padding: 16px;
  }
}

.part__header {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

.h2 {
  display: block;
  color: var(--color-text-gray);
  font-weight: bold;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  margin-bottom: 56px;
}

.h3 {
  display: block;
  color: var(--color-text-gray);
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
}

.MuiCheckbox-root {
  path {
    color: var(--color-primary);
  }
}

.margin-bottom {
  margin-bottom: 16px !important;
}

.margin-right {
  margin-right: 16px !important;
}

._clickable {
  cursor: pointer;
}

button.MuiButton-root, button.MuiButton-root:hover {
  &._large {
    height: 56px;
  }

  &.primary {
    background: var(--color-primary);

    &:hover {
      background-color: var(--color-primary-hover);
    }

    span {
      text-transform: uppercase;
    }
  }

  &.secondary {
    border: 1px solid var(--color-primary);
    background-color: var(--color-background);

    &:hover {
      border-color: var(--color-primary-hover);
    }

    span {
      color: var(--color-text);
      text-transform: uppercase;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }

  &._no-boarders {
    border: none;
  }

  &._white {
    border-color: var(--color-white);
    span {
      color: var(--color-white);
    }

  }

  border: 1px solid var(--color-primary);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 24px;

  span {
    color: var(--color-white);
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-transform: none;
  }

  &.Mui-disabled {
    background-color: var(--color-disabled);
    border-color: var(--color-disabled);
  }
}
