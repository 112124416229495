html {
    --color-primary: #6290dd;
    --color-primary-hover: #275bb2;
    --color-disabled: #adadaf;
    --color-white: #FFFFFF;
    --color-text: #3e3f42;
    --color-text-gray: #3e3f42;
    --color-placeholder: #757575;
    --color-header: #f7f7f7;
    --color-background-light: #ebeff5;
    --color-background: #f7f7f7;
    --color-dark: #0f1c32;
}
