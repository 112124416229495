.main-part {
  margin-top: 64px;
  background: var(--color-background-light);
  position: relative;
  @media (max-width: 1200px) {
    margin-top: 0;
  }

  .background {
    width: 100%;
    min-height: 450px;
    pointer-events: none;
    //filter: brightness(40%);

    @media (max-width: 800px) {
      min-height: 500px !important;
      object-fit: cover;
      object-position: 10%;
    }
  }

  .background-small {
    width: 100%;
    min-height: 350px;
    pointer-events: none;
    @media (min-width: 1200px) {
      display: none;
    }
  }

  .gradient {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background: linear-gradient(89.96deg, rgba(0, 0, 0, 0.72) 0.89%, rgba(0, 0, 0, 0.5) 98.3%);
  }

  .content {
    position: absolute;
    bottom: 0;
    margin-left: 9%;
    margin-bottom: 180px;

    @media (max-width: 1200px) {
      padding-left: 16px;
      margin-bottom: 140px;

      @media (max-width: 800px) {
        padding-top: 50px;
        margin-left: 0;
        width: calc(100% - 32px);
      }
    }

    .h2, .h3 {
      text-align: left;
      color: var(--color-white);
    }

    .h2 {
      margin-bottom: 12px;
      font-weight: 700;
      font-size: 44px;
    }

    .h3 {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 32px;
    }

    @media (max-width: 1200px) {
      .h2 {
        font-size: 30px;
      }

      .h3 {
        font-size: 24px;
      }
    }

    .controls {
      display: flex;
      @media (max-width: 800px) {
        flex-direction: column;
        button {
          width: 100% !important;

          &:first-child {
            margin-right: 0;
            margin-bottom: 12px;
          }
        }
      }

      button {
        width: 230px;

        &.primary {
          background-color: #ff9c54;
          border-color: #ff9c54;

          &:hover {
            background-color: #ed6400;
            border-color: #ed6400;
          }
        }

        &:first-child {
          margin-right: 24px;
        }
      }
    }
  }
}
